/**
 * omega-teknik.generic-form
 * Copyright (C) Sunstate AB - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Simon Wikstrand <simon@sunstate.se>, 2016-10-26
 */

$(function() {
    'use strict';

    // Input button
    var submitButton = $('#submit');

    // All input fields
    var inputFields = $('input').not('#submit').not('[type=hidden]');

    var selectFields = $('select');

    // Do some actions when the user submitted.
    submitButton.on('click', function (e) {
        e.preventDefault();

        var Request = {};

        // Save all values from elements
        inputFields.each(function () {
            var Element = $(this);

            if(Element.is(':checkbox')) {
                if(Element.is(':checked')) {
                    Request[Element.attr('data-field')] = Element.val();
                }

                return;
            }

            if(Element.is(':radio')) {
                if (Element.is(':checked')) {
                    Request[Element.attr('data-field')] = Element.val();
                }

                return;
            }

            Request[Element.attr('data-field')] = Element.val();
        });

        // Save all values from select fields
        selectFields.each(function () {
          Request[$(this).attr('data-field')] = $(this).val();
        });

        // Do ajax request
        $.ajax({
            method: "POST",
            url: '/v1/register',
            data: JSON.stringify(Request),
            dataType: "text",
            contentType: "application/json; charset=utf-8",
            success: function (data) {
                window.location = "/receipt";
            },
            statusCode: {
                412: function (e) {
                    var errors = JSON.parse(e.responseText);

                    inputFields.each(function () {
                        if(errors.hasOwnProperty($(this).attr('data-field'))) {
                            $(this).closest('.catcher').addClass('invalid');
                        }
                    });
                }
            }
        });
    });

    // On keyup, remove invalid class
    inputFields.on('keyup', function () {
        $(this).closest('.catcher').removeClass('invalid');
    });

    // On change, update color
    selectFields.on('change', function () {
      $(this).css({color: '#000000'})
    });

    // Add autoComplete for those inputs that has it
    inputFields.each(function () {
        var autoComplete = [];
        $(this).siblings('.autoComplete').each(function () {
            autoComplete.push($(this).val());
        });

        $(this).autocomplete({
            source: autoComplete
        });
    });
});